html, body {
    background-color: #181a1b !important;

    overflow-x: hidden;

    width: 100%;
    height: 100%;
    margin: 0;
}

body {
    padding: 22px;

    display: grid;
    grid-template-columns: auto 1fr;

    gap: 22px;
}

* {
    color: rgba(255, 255, 255, .87);
    font-family: Roboto, sans-serif;

    box-sizing: border-box;
}

#sidebar {
    width: 20vw;
}

#sidebar, #note {
    background-color: #1c1e1f;

    height: 100%;

    padding: 12px;

    border-radius: 4px;

    box-shadow: rgba(0, 0, 0, .3) 0 0 10px;
}

#search {
    width: 100%;

    background-color: transparent;

    border: none;

    border-bottom: 2px solid #181a1b;

    font-size: 24px;

    margin-bottom: 20px;
}

.card {
    background-color: #2a2c2e;
    border-radius: 2px;
}

#results > * {
    margin-bottom: 20px;
}

.card > * {
    padding: 8px;
}

.card > :first-child, #note-title {
    padding: 8px;

    font-weight: bold;
    font-size: 22px;

    border-bottom: 2px solid #181a1b;
}

.card > :last-child, #note-content {
    padding: 10px;
    font-size: 16px;
}

.card > :first-child > :last-child {
    float: right;

    cursor: pointer;
}

#add-note {
    width: 40px;
    height: 40px;
    line-height: 40px;

    text-align: center;

    background-color: #2a2c2e;
    border-radius: 50%;
    
    font-size: 26px;

    position: absolute;
    bottom: calc(22px + 12px);
    left: calc(20vw - 12px - 20px);

    cursor: pointer;

    box-shadow: rgba(0, 0, 0, .1) 0 0 5px;
}

.CodeMirror {
    background: #181a1b;
    border: 1px solid #666;
    height: 77vh;
}

.CodeMirror .CodeMirror-cursor {
  border-left: 1px solid rgba(255, 255, 255, .87);
}

.editor-preview {
    background: #181a1b;
}

.editor-toolbar a {
    color: unset !important;
}

::-webkit-scrollbar {
    background-color: #0a0a0a;
    color: #c5c1b9;
}

::-webkit-scrollbar-thumb {
    background-color: #2a2c2e;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #323537;
}

::-webkit-scrollbar-thumb:active {
    background-color: #3d4043;
}

::-webkit-scrollbar-corner {
    background-color: #181a1b;
}

a[title="Toggle Side by Side (F9)"], a[title="Toggle Fullscreen (F11)"] {
    display: none !important;
}

.editor-toolbar a.active, .editor-toolbar a:hover {
    background: #323537;
}

.editor-toolbar.disabled-for-preview a:not(.no-disable) {
    background: #0a0a0a;
}

* {
    scrollbar-color: #2a2c2e #1c1e1f;
}
